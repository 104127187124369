import styled from "styled-components";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const Wrapper = styled.div`
  max-widht: ${(props) => props.theme.sizes.contentMaxWidth};

  padding: 24px;
  ${MEDIA.PHONE`
    padding: 12px;
  `}
`;

export const TitleWrapper = styled.div`
  width: 100%;
  padding: 32px 0;
  background-color: ${(props) => props.theme.primary};
  margin: auto;
  display: flex;
`;

export const TitleContainer = styled.div`
  max-width: ${(props) => props.theme.sizes.contentWideMaxWidth};
  margin: auto;
  width: 100%;
  display: flex;
  ${MEDIA.TABLET`
    flex-direction: column;
  `}
`;

export const VimeoIframe = styled.iframe`
  width: 100%;
  min-height: 350px;
`;

export const ContentColumn = styled.div`
  width: 75%;
  padding: 24px;
  ${MEDIA.TABLET`
  width: 100%;
  `}
  ${MEDIA.PHONE`
    padding: 12px;
  `}
`;
export const ActionColumn = styled.div`
  width: 35%;
  padding: 12px;
  * {
    color: white;
  }
  ${MEDIA.TABLET`
    width: 100%;
  `}
`;

export const PriceBox = styled.span`
  display: block;
  padding: 10px 0 30px;
`;
