import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import Layout from "../../components/Layout/Layout";
import pathBuilder from "../../utils/pathBuilder";
import * as Styled from "./styled";
import ContentfulRenderer from "../../components/System/ContentfulRenderer";
import { useAppContext } from "../../context/appProvider";
import Button from "../../components/UI/Button";
import VimeoIframe from "../../components/VimeoIframe/VimeoIframe";

const CoursesTemplate = (props) => {
  const ctx = useAppContext();

  const { state, dispatch } = ctx;
  const [isBought, set_isBought] = useState(false);
  const [isInCart, set_isInCart] = useState(false);

  const {
    content,
    node_locale,
    intro,
    slug,
    seoDescription,
    seoTitle,
    title,
    presentationVideoLink,
    lessons,
    contentful_id,
    price,
    __typename,
    productId,
  } = props.data.course;

  useEffect(() => {
    if (
      state.user.purchased &&
      state.user.purchased.find((e) => e.productId === productId)
    ) {
      set_isBought(true);
      return;
    }
    if (
      state &&
      state.cart &&
      state.cart.items &&
      state.cart.items.find((e) => e.id === productId)
    )
      set_isInCart(true);
  }, [state?.cart?.items]);

  // console.log(props.data);

  const environment = {
    lang: node_locale,
    id: contentful_id,
    path: pathBuilder(node_locale, __typename, slug),
    // imgUrl: image.localFile.url,
    theme: "pink",
    altHrefLang: [
      {
        lang: node_locale,
        path: pathBuilder(node_locale, __typename, slug),
      },
    ],
  };
  const seo = {};
  if (seoDescription) {
    seo.description = seoDescription;
  }
  if (seoTitle) {
    seo.title = seoTitle;
  } else {
    seo.title = title;
  }
  environment.seo = seo;

  return (
    <Layout environment={environment}>
      <Styled.TitleWrapper>
        <Styled.TitleContainer>
          <Styled.ContentColumn>
            <VimeoIframe videoLink={presentationVideoLink} title={title} />
          </Styled.ContentColumn>

          <Styled.ActionColumn>
            <h1>{title}</h1>
            <div>
              <p>{intro}</p>
              <Styled.PriceBox>
                <strong>LESSONS</strong>: {lessons.length} |{" "}
                <strong>PRICE</strong>: {price}
              </Styled.PriceBox>
            </div>
            {isBought ? (
              <Link to={`/en/user/course/${productId}`}>
                <Button.ContrastPrimary>Continue course</Button.ContrastPrimary>
              </Link>
            ) : isInCart ? (
              <Link to={"/en/cart"}>
                <Button.ContrastPrimary>Go to checkout</Button.ContrastPrimary>
              </Link>
            ) : (
              <Button.ContrastPrimary
                onClick={() =>
                  dispatch({
                    type: "ADD-TO-CART",
                    payload: {
                      id: productId,
                      title: title,
                      type: "video-course",
                      slug: pathBuilder("en", "ContentfulCourses", slug),
                      price: price,
                    },
                  })
                }
              >
                Add to cart
              </Button.ContrastPrimary>
            )}
          </Styled.ActionColumn>
        </Styled.TitleContainer>
      </Styled.TitleWrapper>
      <Styled.Wrapper>
        <ContentfulRenderer json={content} lang={environment.lang} />
      </Styled.Wrapper>
    </Layout>
  );
};

export const courseQuery = graphql`
  query courseQuery($lang: String!, $id: String!) {
    course: contentfulCourses(
      contentful_id: { eq: $id }
      node_locale: { eq: $lang }
    ) {
      slug
      __typename
      title
      intro
      showFaq
      node_locale
      price
      presentationVideoLink
      lessons {
        title
      }
      productId
      contentful_id
      content {
        raw
      }
    }
  }
`;

export default CoursesTemplate;
