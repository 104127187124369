import React from "react";
import styled from "styled-components";
// import Helmet from "react-helmet";

const VimeoIframeContainer = styled.iframe`
  position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `;
const VideoWrapper = styled.div`
  position: relative;
  padding: 56.25% 0 0 0;
  margin-bottom: 2rem; 
`;


const VimeoEmbed = ({ videoLink, title }: { videoLink: string, title: string }) => {
  if (videoLink.includes("iframe") && videoLink.match(/src\s*=\s*"(.+?)"/) && videoLink.match(/src\s*=\s*"(.+?)"/).length > 0) {
    return (
      <VimeoIframeContainer
        src={videoLink.match(/src\s*=\s*"(.+?)"/)[1]}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        title={title ? title : "a video by The Dutch Online Academy"}
      />
    )
  }


  const videoPieces = videoLink.split("/");
  const videoId = videoPieces[videoPieces.length - 1]
  const accountId = videoPieces[videoPieces.length - 2]
  if (!isNaN(accountId as any)) return (<VimeoIframeContainer
    // eslint-disable-next-line prettier/prettier
    src={`https://player.vimeo.com/video/${accountId
      // eslint-disable-next-line prettier/prettier
      }?h=${videoId
      // eslint-disable-next-line prettier/prettier
      }&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
    frameBorder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
    title={title ? title : "a video by The Dutch Online Academy"}
  />)

  return (<VimeoIframeContainer
    // eslint-disable-next-line prettier/prettier
    src={`https://player.vimeo.com/video/${videoId
      // eslint-disable-next-line prettier/prettier
      }?h=a88b686588&&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
    frameBorder="0"
    allow="autoplay; fullscreen; picture-in-picture"
    allowFullScreen
    title={title ? title : "a video by The Dutch Online Academy"}
  />)
}


const VimeoIframe = ({ videoLink, title }: { videoLink: string, title: string }) => {
  // const vimeoEmbedCode = getVimeoEmbedCode(videoLink, title);
  return (
    <VideoWrapper >
      <VimeoEmbed videoLink={videoLink} title={title} />
      <script src="https://player.vimeo.com/api/player.js"></script>
    </VideoWrapper>
  );
};

export default VimeoIframe;
